import React from "react";
import { Stack, Typography, DataGrid, Tooltip } from "../../../ui/core";
import { useFormatters } from "../../../hooks";
import { TripStatus } from "../../../models/Trips";
import UserCard from "./UserCard";
import TripStatusChip from "./TripStatusChip";
import TimeRangeVisual from "./TimeRangeVisual";
import TripTags from "./TripTags";
import TripETA from "./TripETA";

import { StationIcon, DollarBadgeIcon } from "../../../ui/icons";
import { theme } from "../../../ui/styles";

const style = {
  dataGrid: {
    ".trip--return, .trip--previous, .trip--next": {
      position: "relative",
      backgroundColor: "rgba(0, 0, 0, 0.06)",
      "&:hover, &.Mui-selected": {
        backgroundColor: (theme) => theme.palette.action.selected,
      },
      zIndex: 2,
      color: (theme) => theme.palette.text.secondary,
      ".DataGrid-cell": {
        overflow: "visible",
      },
    },
    ".trip--return, .trip--next": {
      ".trip__departure": {
        "> div:first-child": {
          position: "absolute",
          left: "16px",
          bottom: "16px",
        },
      },
    },
    ".trip--previous": {
      ".trip__departure": {
        "> div:first-child": {
          position: "absolute",
          left: "16px",
          top: "16px",
        },
      },
    },
  },
};

const TRIP_COLUMNS = [
  { field: "id", hide: true },
  { field: "departureDate", hide: true },
  {
    field: "departure",
    headerName: "Departure",
    width: 160,
    renderCell: ({ row }) => {
      if (row.isPrevious || row.isNext) {
        return (
          <span className="trip__departure">
            <TimeRangeVisual />
            <Typography variant="body2" color="text.secondary">
              {row.departure}
            </Typography>
          </span>
        );
      }
      return <Typography>{row.departure}</Typography>;
    },
  },
  {
    field: "eta",
    headerName: "ETA",
    width: 160,
    renderCell: ({ row }) => {
      if (
        row.isPrevious ||
        row.isNext ||
        row.status !== TripStatus.in_progress
      ) {
        return <span></span>;
      }
      return <TripETA trip={row} />;
    },
  },
  {
    field: "displayStatus",
    headerName: "Status",
    width: 180,
    renderCell: ({ row }) =>
      row.isNext || row.isPrevious ? (
        <span></span>
      ) : (
        <Stack direction="row" spacing="5px">
          <TripStatusChip status={row.status} />
          <TripTags tags={row.tags} />
        </Stack>
      ),
  },
  {
    field: "route",
    headerName: "Route",
    flex: 2,
    renderCell: ({ row }) => {
      if (row.isPrevious || row.isNext) {
        return (
          <Stack direction="row" alignItems="center">
            <Typography>{row.route}</Typography>
            <Stack direction="row" alignItems="center" ml="14px" spacing="4px">
              <StationIcon
                sx={{ height: "16px", width: "16px", color: "text.secondary" }}
              />
              <Typography variant="body2" color="text.secondary">
                {row.rides.length}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" ml="14px" spacing="4px">
              <DollarBadgeIcon sx={{ height: "16px", width: "16px" }} />
              <Typography variant="body2" color="text.secondary">
                {row.payout}
              </Typography>
            </Stack>
          </Stack>
        );
      }
      return (
        <Stack direction="row" alignItems="center">
          <Typography>{row.route}</Typography>
          <Stack direction="row" alignItems="center" ml="14px" spacing="4px">
            <StationIcon
              sx={{ height: "16px", width: "16px", color: "text.secondary" }}
            />
            <Typography variant="body2" color="text.secondary">
              {row.rides.length}
            </Typography>
          </Stack>
        </Stack>
      );
    },
  },
  {
    field: "driver",
    headerName: "Driver",
    flex: 1,
    renderCell: ({ row }) => {
      if (row.isPrevious || row.isNext) {
        return <span></span>;
      }
      return row.driver ? <UserCard user={row.driver} /> : null;
    },
  },
  {
    field: "riders",
    headerName: "Passengers",
    flex: 2,
    renderCell: ({ row }) => {
      if (row.isPrevious || row.isNext) {
        return <span></span>;
      }
      const getRideStatusText = (status, riderFirstName) => {
        switch (status) {
          case "completed":
            return "Ride completed";
          case "en_route":
            return `${riderFirstName} is being dropped off`;
          case "accepted":
            return `${riderFirstName} is being picked up`;
          case "processing":
            return "Awaiting driver acceptance";
          case "finalizing_details":
            return "Awaiting match time";
          default:
            return "";
        }
      };

      return (
        <Stack direction="row" spacing={1}>
          {row.rides.map((r) => (
            <Stack
              key={r.rider.id}
              direction="row"
              alignItems="center"
              spacing={0.5}
            >
              {r.status &&
                r.status !== "processing" &&
                r.status !== "finalizing_details" && (
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                      backgroundColor:
                        r.status === "completed"
                          ? theme.palette.success.main
                          : r.status === "en_route"
                            ? theme.palette.error.main
                            : r.status === "accepted"
                              ? theme.palette.accent.main
                              : theme.palette.accent.main,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    {r.status === "completed"
                      ? "✓"
                      : r.status === "en_route"
                        ? "↓"
                        : "↑"}
                  </div>
                )}
              <Tooltip
                title={getRideStatusText(r.status, r.rider.firstName)}
                arrow
              >
                <Typography
                  sx={{
                    textDecoration: "underline",
                    textDecorationStyle: "dotted",
                    cursor: "help",
                  }}
                >{`${r.rider.firstName} ${r.rider.lastName}`}</Typography>
              </Tooltip>
            </Stack>
          ))}
        </Stack>
      );
    },
  },
  {
    field: "payout",
    headerName: "Payout",
    flex: 1,
    renderCell: ({ row }) =>
      row.isPrevious || row.isNext ? (
        <span></span>
      ) : (
        <Typography>{row.payout}</Typography>
      ),
  },
];

const TripsTable = ({ data, loading, getColumn, getRow, onRowClick }) => {
  const f = useFormatters();

  const columns = getColumn
    ? TRIP_COLUMNS.map((c) => getColumn({ ...c }))
    : TRIP_COLUMNS;

  let rows = data
    .map((t) => ({
      trip: t,
      previousTrip: data.find((ot) => ot.id === t.previousTrip),
      returnTrip: data.find(
        (rt) => rt.tripType === "return" && t.id === rt.previousTrip,
      ),
      isReturn:
        t.tripType === "return" && data.find((ot) => ot.id === t.previousTrip),
    }))
    .reduce((_rows, d) => {
      const getRow = (t, { isPrevious = false, isNext = false } = {}) => {
        return {
          id: t.id,
          internalId: t.internalId || t.id,
          departureTime: t.departureTime,
          departure: f.date.toTimezoneLocaleString(
            t.departureTime,
            t.originCity.timeZone,
            f.date.DateFormats.TIME_SIMPLE_WITH_TIMEZONE,
          ),
          route: `${t.originCity.cityName} ➔ ${t.destinationCity.cityName}`,
          driver: t.driver,
          status: t.status,
          displayStatus: t.displayStatus,
          rides: t.rides,
          payout: f.number.currency(t.payout || 0),
          isPrevious,
          isNext,
          tags: t.tags,
          currentWaypoint: t.currentWaypoint,
          waypoints: t.waypoints,
          originCity: t.originCity,
        };
      };

      if (d.returnTrip) {
        _rows.push(getRow(d.trip));
        _rows.push(
          getRow(
            {
              ...d.returnTrip,
              displayStatus: d.trip.displayStatus,
              internalId: `${d.trip.id}_next`,
            },
            { isNext: true },
          ),
        );
        return _rows;
      }

      if (
        d.isReturn &&
        d.previousTrip &&
        d.previousTrip.displayStatus !== d.trip.displayStatus
      ) {
        _rows.push(
          getRow(
            {
              ...d.previousTrip,
              displayStatus: d.trip.displayStatus,
              internalId: `${d.trip.id}_previous`,
            },
            { isPrevious: true },
          ),
        );
        _rows.push(getRow(d.trip));
        return _rows;
      }

      _rows.push(getRow(d.trip));
      return _rows;
    }, []);

  if (getRow) {
    rows = rows.map((row) => getRow(row));
  }

  return (
    <DataGrid
      sx={style.dataGrid}
      loading={loading}
      rows={rows}
      columns={columns}
      onRowClick={onRowClick}
      hideFooterPagination
      getRowClassName={({ row }) => {
        if (row.isPrevious) {
          return "trip--previous";
        }
        if (row.isNext) {
          return "trip--next";
        }
        return "";
      }}
      getRowHeight={({ model }) => (model.isPrevious || model.isNext ? 40 : 64)}
      getRowId={(row) => row.internalId}
    />
  );
};

export default TripsTable;

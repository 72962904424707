import React, { useState } from 'react';
import { useFormatters, useTextMasks, useValidators } from "../../../hooks";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, TextField, Divider } from "../../../ui/core";
import { useDispatch } from 'react-redux';
import { updateTripPayout } from '../../../redux/Trips/actions';

const TripPayoutField = ({ trip }) => {
  const f = useFormatters();
  const m = useTextMasks();
  const v = useValidators();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { autoBonus, base, premier, manualBonus } = trip.payoutItemized || {};
  const initialValue = f.number.currency(manualBonus || 0);

  const [value, setValue] = useState(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const isValid = v.text.currency()(value) === true;
  const hasChanges = value !== initialValue;

  const onSave = async () => {
    setIsSaving(true);
    const update = {
      manualBonus: Number.parseFloat(value.replace("$", ""))
    };
    const { success } = await updateTripPayout(trip.id, update)(dispatch);
    setIsSaving(false);
    if (success) {
      setOpen(false);
    }
  }

  return <>
    <Button onClick={() => setOpen(true)}>
    {f.number.currency(trip.payout)}
  </Button>
  <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle>Edit Payout</DialogTitle>
    <Divider />
    <DialogContent>
      <Stack direction="row" spacing="20px" py="20px">
        <TextField label="Base Payout" value={f.number.currency(base)} disabled />
        <TextField label="Auto Bonus" value={f.number.currency(autoBonus)} disabled />
      </Stack>
      <Stack direction="row" spacing="20px" py="20px">
        <TextField label="Premier" value={f.number.currency(premier)} disabled />
        <TextField
          label="Manual Bonus"
          value={value}
          mask={m.text.currency}
          onChange={(e) => setValue(e.target.value)}
        />
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button color="error" onClick={() => setOpen(false)}>Cancel</Button>
      <Button
        disabled={!(isValid && hasChanges)}
        loading={isSaving}
        onClick={onSave}
      >Update Payout</Button>
    </DialogActions>
  </Dialog>
  </>;
};

export default TripPayoutField;

import React, { useEffect } from 'react';
import { Dialog, DialogContent, Stack, IconButton, Box, Typography, Divider, Slide, Grid, Button } from '../../../ui/core';
import { ContentCopyIcon, CloseFullscreenIcon, OpenFullscreenIcon, StationIcon, CloseIcon } from '../../../ui/icons';

import { useFormatters, useCopyToClipboard, useToast } from '../../../hooks';
import { getTripETA } from '../../../redux/Trips/actions';
import { useDispatch } from 'react-redux';

import TripStatusChip from './TripStatusChip';
import RidersList from './RidersList';
import TripMap from './TripMap';
import TripDriverField from './TripDriverField';
import TripTagsField from './TripTagsField';
import TripPayoutField from './TripPayoutField';
import UserCard from './UserCard';
import TripOverrideCitiesField from './TripOverrideCitiesField';
import TripItinerary from './TripItinerary';
import TripActivity from './TripActivity';

const style = {
  heading: {
    padding: '12px 16px',
  },
  section: {
    padding: '32px 48px'
  },
  dataList: {
    display: 'grid',
    gridTemplateColumns: '124px 1fr',
    rowGap: '8px',
    margin: 0,
    '> dt': {
      gridColumn: 1,
      height: '48px',
      display: 'flex',
      alignItems: 'center',
    },
    '> dd': {
      gridColumn: 2,
      height: '48px',
      display: 'flex',
      alignItems: 'center',
    }
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" timeout={900} ref={ref} {...props} />;
});

const TripDetailsDialog = ({ trip, fullScreen, returnTrip, onClose, onFullscreen }) => {
  const f = useFormatters();
  const c = useCopyToClipboard();
  const t = useToast();
  const dispatch = useDispatch();

  const vehicle = trip && trip.driver ? trip.driver.vehicles.find((v) => v.active) : null;

  const copyIdToClipboard = () => {
    c.copy(trip.id);
    t.success("Trip id copied to clipboard");
  };

  useEffect(() => {
    trip && getTripETA(trip.id)(dispatch);
  }, [trip && trip.id]);

  return (<Dialog
    sx={{ left: 'auto', width: fullScreen ? '100%' : '60%', minWidth: '840px' }}
    fullScreen
    open={!!trip}
    TransitionComponent={Transition}
    onClose={onClose}
  >
    <Stack position="relative" direction="row" alignItems="center" flexWrap="wrap" spacing="16px" py="6px" px="48px">
      <IconButton
        sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '8px' }}
        onClick={onClose}
      >
        <CloseIcon sx={{ color: "text.secondary" }} />
      </IconButton>
      {
        trip && <>
          <Box display="flex" alignItems="center">
            <StationIcon sx={{ fontSize: '16px' }} />
            <Typography ml="5px" mr="10px">Trip {trip.id}</Typography>
           <IconButton size="small" onClick={copyIdToClipboard}>
            <ContentCopyIcon sx={{ fontSize: "16px" }} />
           </IconButton>
          </Box>
          <TripStatusChip status={trip.status} />
          <TripTagsField trip={trip} />
        </>
      }
      <IconButton
        sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '8px' }}
        onClick={() => onFullscreen(!fullScreen)}
      >
        {
          fullScreen ?
          <CloseFullscreenIcon sx={{ fontSize: '24px' }} /> :
          <OpenFullscreenIcon sx={{ fontSize: '24px' }} />
        }
      </IconButton>
    </Stack>
    <Divider />
    <DialogContent sx={{ padding: '32px 40px' }}>
      <Grid container spacing="32px">
        <Grid item xs={fullScreen ? true : 12}>
          <Stack spacing="24px">
            {
              trip && (<Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: '28px', lineHeight: '32px' }}>
                  {trip.originCity.cityName} ➔ {trip.destinationCity.cityName}
                </Typography>
                <Stack direction="row" alignItems="center" spacing="4px" ml="18px" mr="8px">
                  <StationIcon sx={{ fontSize: "16px", color: "text.secondary" }} />
                  <Typography color="text.secondary">{ trip.rides.length }</Typography>
                </Stack>
                <TripOverrideCitiesField trip={trip} />
              </Box>)
            }
            <Box component="dl" direction="row" sx={style.dataList}>
              <dt><Typography color="text.secondary">Departure</Typography></dt>
              <dd>
                {
                  trip && <>
                    <Typography>
                      { f.date.toTimezoneLocaleString(trip.departureTime, trip.originCity.timeZone, f.date.DateFormats.TIME_SIMPLE_WITH_TIMEZONE) }
                    </Typography>
                    <Typography>&nbsp;(Not editable)</Typography>
                  </>
                }
              </dd>
              <dt><Typography color="text.secondary">Driver</Typography></dt>
              <dd>{ trip && <TripDriverField trip={trip} /> }</dd>
              <dt><Typography color="text.secondary">Phone</Typography></dt>
              <dd>{ trip && trip.driver ? f.phone.toInternational(trip.driver.phoneNumber) : "" }</dd>
              <dt><Typography color="text.secondary">Vehicle</Typography></dt>
              {
                vehicle && <dd>
                  <Typography>{vehicle.year} {vehicle.make} {vehicle.model} { vehicle.licensePlate }</Typography>
                </dd>
              }
              <dt><Typography color="text.secondary">Payout</Typography></dt>
              <dd>{ trip && <TripPayoutField trip={trip} /> }</dd>
              <dt><Typography color="text.secondary">Views</Typography></dt>
              <dd>{ trip &&
                <Typography>
                  {trip.viewCount} {f.string.noun("time", "times", trip.viewCount)} by {trip.viewedByCount} {f.string.noun("driver", "drivers", trip.viewedByCount)}
                </Typography> }
              </dd>
            </Box>
            <Divider />
            <Box>
              <Typography sx={{ fontSize: '20px' }}>Riders</Typography>
              { trip && <RidersList rides={trip.rides} trip={trip} /> }
            </Box>
            <Divider />
            {
              trip && trip.cancelledRides.length > 0 && (<>
                <Box>
                  <Typography sx={{ fontSize: '20px' }}>Cancelled riders</Typography>
                  { trip && <RidersList rides={trip.cancelledRides} trip={trip} /> }
                </Box>
                <Divider />
              </>)
            }
            <Box>
              <Typography sx={{ fontSize: '20px', marginBottom: "20px" }}>Activity</Typography>
              { trip && <TripActivity trip={trip} /> }
            </Box>
            <Divider />
            {
              returnTrip && (<>
                <Box>
                  <Stack direction="row" spacing="24px" mb="20px">
                    <Typography sx={{ fontSize: '20px' }}>Return</Typography>
                    <Box display="flex" alignItems="center">
                      <StationIcon sx={{ fontSize: "16px", color: "text.secondary" }} />
                      <Typography color="text.secondary">{returnTrip.id}</Typography>
                    </Box>
                  </Stack>
                  <Box component="dl" direction="row" sx={style.dataList}>
                    <dt><Typography color="text.secondary">Departure</Typography></dt>
                    <dd>
                      <Typography>
                        { f.date.toTimezoneLocaleString(
                            returnTrip.departureTime,
                            returnTrip.originCity.timeZone,
                            f.date.DateFormats.TIME_SIMPLE_WITH_TIMEZONE
                          )
                        }
                      </Typography>
                      <Typography>&nbsp;(Not editable)</Typography>
                    </dd>
                    <dt><Typography color="text.secondary">Riders</Typography></dt>
                    <Stack component="dd" direction="row" spacing={3}>
                        { returnTrip.rides.map((r) => <UserCard key={r.id}  user={r.rider}/>)}
                    </Stack>
                    <dt><Typography color="text.secondary">Payout</Typography></dt>
                    <dd>{f.number.currency(returnTrip.payout)}</dd>
                  </Box>
                </Box>
                { !fullScreen && <Divider /> }
              </>)
            }
          </Stack>
        </Grid>
        {
          fullScreen && (<Grid item xs="auto">
          <Divider orientation="vertical" />
        </Grid>)
        }
        <Grid item xs={fullScreen ? "auto" : 12}>
          <Box>
            { trip && <TripItinerary trip={trip} /> }
          </Box>
          <Box>
            <Typography sx={{ fontSize: '20px', marginBottom: '24px' }}>Map</Typography>
            { trip && <TripMap trip={trip} /> }
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>)
}

export default TripDetailsDialog;
import client from "./client";
import { gql } from "@apollo/client";

const TRIP_QUERY = `
id
status
acceptedAt
tags
departureTime
payout
payoutItemized {
  autoBonus
  base
  emptyReturn
  manualBonus
  premier
  waitTime
}
originCity {
  id
  cityName
  stateAbbr
  timeZone
  cityCoord {
    type
    coordinates
  }
}
destinationCity {
  id
  cityName
  stateAbbr
  cityCoord {
    type
    coordinates
  }
}
departureTimeHistory {
  pastDeptTime
  timestamp
}
rides {
  id
  status
  departureTime
  rider {
    id
    rider_id
    firstName
    lastName
    phoneNumber
    imageUrl
  }
  pickUp {
    name
    address
    type
    coords {
      type
      coordinates
    }
  }
  dropOff {
    name
    address
    type
    coords {
      type
      coordinates
    }
  }
  fare {
    originalAmount
    grossAmount
    finalAmount
    priceLines {
      amount
      displayAmount
      item
    }
  }
  statusUpdates {
    status
    timestamp
  }
  departureTimeHistory {
    pastDeptTime
    timestamp
  }
 }
cancelledRides {
  id
  status
  rider {
    id
    rider_id
    firstName
    lastName
    phoneNumber
    imageUrl
  }
  pickUp {
    name
    address
    type
    coords {
      type
      coordinates
    }
  }
  dropOff {
    name
    address
    type
    coords {
      type
      coordinates
    }
  }
  fare {
    originalAmount
    grossAmount
    finalAmount
    priceLines {
      amount
      displayAmount
      item
    }
  }
}
driver {
  id
  driver_id
  firstName
  lastName
  imageUrl
  phoneNumber
  email
  vehicles {
    licensePlate
    year
    make
    model
    active
  }
}
allowedDrivers {
  id
  driver_id
  firstName
  lastName
  imageUrl
  phoneNumber
  email
}
deniedDrivers {
  id
  driver_id
  firstName
  lastName
  imageUrl
  phoneNumber
  email
}
isRoundTrip
tripType
nextTrip
previousTrip
tags
currentWaypoint
waypoints {
  type
  ride_id
  coordinates {
    lat
    lon
  }
}
viewCount
viewedByCount
`;

const getDailyTrips = async ({ start, end } = {}) => {
  try {
    const data = await client.query(
      gql`
        query dailyTrips($start: String!, $end: String!) {
          dailyTrips(start: $start, end: $end) {
            ${TRIP_QUERY}
          }
        }
      `,
      { start, end },
    );

    return data.dailyTrips;
  } catch (err) {
    console.log("ERROR", err);
  }
};

const getNationalTrips = async ({ start } = {}) => {
  try {
    const data = await client.query(
      gql`
        query nationalTrips($start: String!) {
          nationalTrips(start: $start) {
            ${TRIP_QUERY}
          }
        }
      `,
      { start },
    );
    return data.nationalTrips;
  } catch (err) {
    console.log("ERROR", err);
  }
};

const updateTags = async (tripId, tags) => {
  const data = await client.mutate(
    gql`
      mutation updateTripTags($id: String!, $tags: [String!]!) {
        updateTripTags(id: $id, tags: $tags)
      }
    `,
    { id: tripId, tags },
  );
  return data.updateTripTags;
};

const cancelEstimate = async (tripId) => {
  const data = await client.query(
    gql`
      query tripCancelEstimate($id: String!) {
        tripCancelEstimate(id: $id) {
          reason
          penalty
          newScore
          currentScore
        }
      }
    `,
    { id: tripId },
  );
  return data.tripCancelEstimate;
};

const cancelDriver = async (tripId, estimate) => {
  const data = await client.mutate(
    gql`
      mutation cancelTripDriver($id: String!, $input: TripCancelDriverInput!) {
        cancelTripDriver(id: $id, input: $input)
      }
    `,
    { id: tripId, input: estimate },
  );
  return data.cancelTripDriver;
};

const updateAllowedDrivers = async (tripId, driverIds) => {
  const data = await client.mutate(
    gql`
      mutation updateAllowedDrivers($id: String!, $driverIds: [String!]!) {
        updateAllowedDrivers(id: $id, driverIds: $driverIds)
      }
    `,
    { id: tripId, driverIds },
  );
  return data.updateAllowedDrivers;
};

const updateDeniedDrivers = async (tripId, driverIds) => {
  const data = await client.mutate(
    gql`
      mutation updateDeniedDrivers($id: String!, $driverIds: [String!]!) {
        updateDeniedDrivers(id: $id, driverIds: $driverIds)
      }
    `,
    { id: tripId, driverIds },
  );
  return data.updateTripTags;
};

const getTripETA = async (tripId) => {
  const data = await client.query(
    gql`
      query tripEta($id: String!) {
        tripEta(id: $id) {
          tripId
          tripPolyline
          rides {
            rideId
            pickUp {
              eta
            }
            dropOff {
              eta
            }
          }
        }
      }
    `,
    { id: tripId },
  );
  return data.tripEta;
};

const getTripRoute = async (tripId) => {
  const data = await client.query(
    gql`
      query tripRoute($id: String!) {
        tripRoute(id: $id) {
          tripId
          polyline
        }
      }
    `,
    { id: tripId },
  );
  return data.tripRoute;
};

const getTripDriverHistory = async (tripId) => {
  const data = await client.query(
    gql`
      query tripDriverHistory($id: String!) {
        tripDriverHistory(id: $id) {
          driver {
            id
            firstName
            lastName
          }
          acceptedAt
          cancelledAt
          reason
        }
      }
    `,
    { id: tripId },
  );
  return data.tripDriverHistory;
};

export default {
  getDailyTrips,
  getNationalTrips,
  updateTags,
  cancelEstimate,
  cancelDriver,
  updateAllowedDrivers,
  updateDeniedDrivers,
  getTripETA,
  getTripRoute,
  getTripDriverHistory,
};

import moment from 'moment';
export const TripDisplayStatus = {
  needs_atention: "Needs Atention",
  in_progress: "In Progress",
  finding_driver: "Finding Driver",
  finalizing_details: "Finalizing Details",
  completed: "Completed",
  cancelled: "Cancelled"
};

export const TripStatus = {
  // No driver has accepted the trip
  processing: "processing",
  // Driver accepted the trip, but hasn't been "matched"
  finalizing_details: "finalizing_details",
  // Driver and riders are matched
  in_progress: "in_progress",
  completed: "completed",
  cancelled: "cancelled",
};

export const TripDisplayStatusOrder = [
  TripDisplayStatus.needs_atention,
  TripDisplayStatus.finding_driver,
  TripDisplayStatus.in_progress,
  TripDisplayStatus.finalizing_details,
  TripDisplayStatus.completed,
  TripDisplayStatus.cancelled,
];

export const getTripDisplayStatus = (trip) => {
  if (trip.status === TripStatus.cancelled) {
    return TripDisplayStatus.cancelled;
  }
  if (trip.status === TripStatus.completed) {
    return TripDisplayStatus.completed;
  }
  if (trip.status === TripStatus.in_progress) {
    return TripDisplayStatus.in_progress;
  }

  const now = moment.utc();
  const departureTime = moment.utc(trip.departureTime);
  if (departureTime.isBefore(now)) {
    return TripDisplayStatus.needs_atention;
  }

  if (!trip.driver) {
    const diffMinutes = departureTime.diff(now, 'minutes');
    return diffMinutes < 60 ? TripDisplayStatus.needs_atention : TripDisplayStatus.finding_driver;
  }

  const lateRide = trip.rides.find((ride) => {
    return moment.utc(ride.departureTime).isBefore(now);
  });
  if (!!lateRide) {
    return TripDisplayStatus.needs_atention;
  }

  return TripDisplayStatus.finalizing_details;
};

export const getTripWaypointETA = (waypoint, tripEta) => {
  if (!tripEta) {
    return null;
  }
  const rideIds = waypoint.map((w) => w.ride_id);
  const rideEta = tripEta.rides.find((r) => rideIds.includes(r.rideId));
  if (!rideEta) {
    return null;
  }
  const rideWaypoint = waypoint.find((w) => w.ride_id === rideEta.rideId); 
  const eta = rideWaypoint.type === 'pick-up' ? rideEta.pickUp.eta : rideEta.dropOff.eta;
  return eta;
};

export const getTripCurrentETA = (trip, tripEta) => {
  const currentWaypoint = trip.currentWaypoint;
  const waypoint = trip.waypoints[currentWaypoint] || [];
  return getTripWaypointETA(waypoint, tripEta);
}

export const isTripNational = (trip) => {
  const states = ["TX", "FL"];
  return (trip.originCity && !states.includes(trip.originCity.stateAbbr)) ||
    (trip.destinationCity && !states.includes(trip.destinationCity.stateAbbr))
}